.taxable-accounts-list {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  height: 200px;
  width:100%;
  max-height: 200px;
  max-width: 100%;
  min-height: 200px;
  min-width: 50%;
  overflow: auto;
  cursor: pointer;
}
