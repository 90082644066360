.taxCenterTabs > .nav-item button:hover {
  background-color: #569dc9;
  color: white;
}

.taxCenterTabs > .nav-item > .active {
  background-color: #569dc9;
  color: white;
}

.taxCenterTabs {
  margin-bottom: 10px;
  color: white;
}

.headerRow {
  background-color: #5576b1;
}
