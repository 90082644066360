:root {
  --eqis-brand: #00aeef;
  --eqis-light-grey: #f2f3f4;
}

/* Client Gain Loss */
/* 
* Content wrapper (.gain-loss-content) adjusts overall position to 
* allow for a Navbar and constrains page-specific classes to the page. 
*/
.gain-loss-content {
  padding-top: 3rem;
}

div#print-banner img {
  height: 6rem;
  margin-bottom: 40px;
}

h5 {
  margin-top: 2rem;
}

select.btn.btn-outline-secondary {
  text-align: left;
}

/* Margins to separate sections */
section.account {
  margin-top: 3rem;
  display: none;
}

/* Suppress margins on first section under page banner headings. */
header[role="banner"] {
  margin-bottom: -2.25rem;
}

/* margin between unrealized and realized "tables" */
section.realized-gain-loss {
  margin-top: 2rem;
}

/* Manage showing/hiding all vs selected accounts */
div.btn-group.account {
  display: none;
}

h4 {
  display: none;
}

h4.selected {
  display: inherit;
}

div.btn-group.account.selected {
  display: inherit;
}

button.account {
  display: none;
}

section.account.selected {
  display: inherit;
}

button.account.selected {
  display: flex;
}

/* Tablular display formatting */
/* reworked because Flex styling combined with Bootstrap row/col 
* classes was causing rows to overflow their container
*/

/* "table" heading */
section.unrealized-gain-loss h5,
section.realized-gain-loss h5 {
  background-color: var(--eqis-brand);
  color: white;
  padding: 0.375rem 0 0.375rem 0.75rem;
  font-size: medium;
  font-weight: bolder;
  margin-top: 0.9375rem;
  margin-bottom: 0;
}

/* compensate for Bootstrap row adjustment and set common styles*/
article .row {
  font-size: small;
  margin-left: 0;
  margin-right: 0;
  padding: 0.375rem 0.625rem;
  text-align: right;
}

.data-row {
  border-bottom: solid;
  border-bottom-color: var(--eqis-light-grey);
  border-bottom-width: 0.1875rem;
}

/* Headings and Totals rows */

article section div.row header {
  background-color: inherit;
  text-align: left;
}

article section header.row,
article section .totals.row {
  font-weight: bold;
  background-color: var(--eqis-light-grey);
}

.alert-eqis-light {
  font-size: small;
  font-weight: bold;
  padding-left: 1.5625rem;
  background-color: var(--eqis-light-grey);
}

/* Show disclosure section using accessible constructs */
.disclosure.all,
.disclosure.account {
  display: none;
}
.disclosure.all.selected,
.disclosure.account.selected {
  display: inherit;
}

.disclosure {
  margin: 10px 10px 10px 10px;
  font-size: small;
}
.disclosure dl {
  display: flex;
  flex-flow: row wrap;
}

.disclosure dt {
  flex-basis: 20%;
  padding: 0.125rem 0.25rem;
  text-align: left;
  border-bottom: solid thin var(--eqis-light-grey);
  page-break-inside: avoid;
}

.disclosure dd {
  flex-basis: 70%;
  flex-grow: 1;
  margin: 0;
  padding: 0.125rem 0.25rem;
  border-bottom: solid thin var(--eqis-light-grey);
  page-break-inside: avoid;
}

.disclosure hr {
  margin: 2rem 20rem;
}
