.eqisNameTaxCenterTitle {
  color: #569dc9;
  font-family: Century Gothic;
  font-size: 72px;
  font-weight: normal;
  line-height: 3px;
  margin-bottom: 0;
  margin-right: 10px;
  padding: 0;
}

.taxCenterTitle {
  color: slategray;
  font-family: Century Gothic;
  font-size: 64px;
  font-weight: normal;
  line-height: 3px;
  margin: 0;
  padding: 0;
}
