.tax-statement-detail-button {
  border: 1px solid gray;
  color: white;
  font-weight: normal;
  margin-left: 4px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}

.tax-statement-summary-button {
  background-color: #ff7600;
  border: 1px solid gray;
  color: white;
  font-weight: normal;
  margin-left: 4px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}

#tax-statement-list {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 330px;
  max-width: 100%;
  min-height: 150px;
  min-width: 100%;
  overflow: auto;
}
