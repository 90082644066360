#search-criteria-input {
  max-width: 48%;
  min-width: 20%;
}

#search-spinner {
  position: absolute;
  vertical-align: middle;
  margin-top: 4px;
  margin-left: 345px;
  z-index: 100;
}

.place_holder::-webkit-input-placeholder {
  text-align: right;
  color: lightgray;
  font-size: x-small;
}

.client-list {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin-bottom: 5px;
  margin-left: 45px;
  max-height: 200px;
  max-width: 100%;
  min-height: 200px;
  min-width: 50%;
  overflow: auto;
  cursor: pointer;
}
