.alert-success {
  background-color: #00986a;
  border-color: grey;
  border-left: 5px solid #00986a;
  color: white;
}

.alert-info {
  background-color: blue;
  border-color: grey;
  border-left: 5px solid blue;
  color: white;
}

.alert-warning {
  background-color: #f9af2c;
  border-color: grey;
  border-left: 5px solid #f9af2c;
  color: black;
}

.alert-danger {
  background-color: #c82630;
  border-color: grey;
  border-left: 5px solid #c82630;
  color: white;
}

.alert-secondary {
  border-color: grey;
  color: white;
  height: 100%;
}

@media (min-width: 768px) {
  .alert {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: table;
    padding-left: 78px;
    padding-right: 60px;
    position: relative;
    width: 100%;
  }

  .alert .icon {
    border: 1px solid #bdbdbd;
    border-radius: 6px 0 0 6px;
    height: 100%;
    left: 0;
    padding-top: 15px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 58px;
  }

  .alert .icon img {
    color: #fff;
    font-size: 20px;
    left: 18px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
  }

  /*============ colors ========*/
  .alert.alert-success .icon,
  .alert.alert-success .icon:after {
    background: #00986a;
    border: none;
  }

  .alert.alert-info .icon,
  .alert.alert-info .icon:after {
    background: blue;
    border: none;
  }

  .alert.alert-warning .icon,
  .alert.alert-warning .icon:after {
    background: #f9af2c;
    border: none;
  }

  .alert.alert-danger .icon,
  .alert.alert-danger .icon:after {
    background: #c82630;
    border: none;
  }
}

.alert {
  width: 100%;
  transition: opacity 1s ease-in-out;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

/* .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: opacity 0.5s;
} */
